<div class="banner">
    <span class="title">O nama</span>
</div>
<div class="section">
    <div class="element">
        <span class="content-text">Tehnički pregled “<b>Čuburac</b>” je nastao leta 2024. godine sa ciljem da
            stanovnicima Beograda ponudi objedinjenu uslugu i ležeran put ka
            registraciji vozila. Bilo da ste početnik ili iskusni vozač, kod nas imate
            veteranski tretman. Ne samo da možete pripremiti vozilo za registraciju,
            već možete obezbediti sve vrste osiguranja koje su vam potrebne.
            <br> <br>
            Pioniri smo u načinu registracije vozila! Vaše vozilo ne samo da testiraju
            nove mašine i iskusni kadar, već vozilo možete registrovati bez dolaska u
            naš objekat. Naše osoblje dolazi po vaše vozili bilo da ste na poslu ili
            odmarate kod kuće i sav posao obavlja umesto vas. Vaše zadovoljstvo je
            naš imperativ!
            </span>
    </div>
    <div class="element">
        <div class="map-container">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11324.875477145932!2d20.46686403155631!3d44.79672798519759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7076d7be4035%3A0x3c51382154a7683f!2z0KfRg9Cx0YPRgNCwLCDQkdC10L7Qs9GA0LDQtA!5e0!3m2!1ssr!2srs!4v1723899093802!5m2!1ssr!2srs"
                allowfullscreen=""
                loading="lazy"
            ></iframe>
        </div>
    </div>
</div>