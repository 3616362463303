import { Component } from '@angular/core';

@Component({
  selector: 'app-dokumentacija',
  standalone: true,
  imports: [],
  templateUrl: './dokumentacija.component.html',
  styleUrl: './dokumentacija.component.scss'
})
export class DokumentacijaComponent {
  public showModal : string = '';

  constructor(){

  }

  toggleModal(modal:string){
    this.showModal = modal;
  }
}
