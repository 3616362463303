import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../config/config';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private pathApi = this.config.setting['pathApi'] + "blog/";

  constructor(private config: AppConfig, private http: HttpClient, private toastr: ToastrService) { }

  save(obj : any) {
    return this.http.post(this.pathApi + 'save', obj);
  }
  getByUrl(obj : string) {
    return this.http.get(this.pathApi + 'getByUrl/'+obj);
  }
  getAll(obj : any) {
    return this.http.post(this.pathApi + 'getAll', obj);
  }
}
