import { Component, OnInit } from '@angular/core';
import { PaginationComponent } from '../pagination/pagination.component';
import { FormsModule } from '@angular/forms';
import { ReferalService } from '../../../services/referal.service';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-referal',
  standalone: true,
  imports: [PaginationComponent,FormsModule, CommonModule],
  templateUrl: './referal.component.html',
  styleUrls: ['./referal.component.scss','./referal-mobile.component.scss']
})
export class ReferalComponent implements OnInit{
  pageData = {
    pageSize: 8,
    currentPage: 1,
    search: ''
  }
  data: any[] = [];
  inviteInput: string = '';
  count: number = 0;
  constructor(private referalService: ReferalService, private toastr:ToastrService, private authService : AuthService){

  }
  isAdmin(){
    return this.authService.isRole('Admin')
  }
  approveReferal(id:number){
    const confirmed = window.confirm("Da li ste sigurni da želite da odobrite referal?");
    if (!confirmed) {
      return;
    }

    this.referalService.approveReferal(id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.reloadTable()
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
  ngOnInit(): void {
    this.reloadTable();
  }

  reloadTable() {
    this.referalService.getAll(this.pageData).subscribe((response: any) => {
      if (response.status == 200) {
        this.data = response.data.data;
        this.count = response.data.count;
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
  pageChange(value: number) {
    this.pageData.currentPage = value
    this.reloadTable()
  }
  callReferal(){
    if(this.inviteInput == '' || this.inviteInput == null)
      this.toastr.error('Morate uneti email adresu ili broj telefona.')

    this.referalService.callreferal(this.inviteInput).subscribe((response: any) => {
      if (response.status == 200) {
        this.inviteInput = '';
        this.toastr.success(response.message);
        this.reloadTable()
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
}
