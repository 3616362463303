<!-- INVITE -->
<div class="invite-row">
    <div class="invite">
        <form (submit)="f.valid" class="form" #f="ngForm">
            <div class="inputItem">
                <label class="labelPopup">Email ili broj telefona</label>
                <input class="form-control" name="name" [(ngModel)]="inviteInput" #name="ngModel" required
                    autocomplete="off" />
            </div>
            <button (click)="callReferal()" type="submit" class="button-sucess">POZOVI KORISNIKA</button>
        </form>
    </div>
</div>
<!-- TABELA -->
<div class="cars">
    <div class="table">
        <table>
            <thead>
                <tr>
                    <th>Email/Telefon</th>
                    <th>Inviter</th>
                    <th>Datum</th>
                    <th>Status</th>
                    <th *ngIf="isAdmin()"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data">
                    <td>{{ item.emailOrPhone }}</td>
                    <td>{{ item.inviter }}</td>
                    <td>{{ item.datum }}</td>
                    <td><span [ngClass]="{
                        'completed': item.status === 'Iskoriscen',
                        'unused': item.status === 'Neiskoriscen',
                        'pending': item.status === 'Cekanje'
                    }">{{ item.status }}</span></td>
                    <td *ngIf="isAdmin()"><img *ngIf="item.status =='Pending'" class="icons" title="Potvrdi referal" (click)="$event.stopPropagation(); approveReferal(item.id)" src="../../../../assets/icons/submit-dark.svg"/></td>
    
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row-table">
        <app-pagination [pageSize]="pageData.pageSize" [currentPage]="pageData.currentPage" [count]="count"
            (pageChange)="pageChange($event)">
        </app-pagination>
    </div>
</div>