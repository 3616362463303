import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, FormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '../../../../../services/message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent implements OnInit {

  constructor(private toastr: ToastrService, private messageService: MessageService, private router: Router) {

  }

  public contactObj : any = {
    name: '',
    email: '',
    phone: '',
    messageContent: ''
  }

  ngOnInit(): void {
  }

  public sendMessage(){
    const number1 = Math.floor(Math.random() * 10) + 1;
    const number2 = Math.floor(Math.random() * 10) + 1;
    const correctAnswer = number1 + number2;
    
    const userAnswer = prompt(`Nisam robot provera | Koliko je ${number1} + ${number2}?`);
    if (userAnswer === null || parseInt(userAnswer) !== correctAnswer) {
      this.toastr.error("Netačan odgovor, molimo pokušajte ponovo.")
      return;
    }

    this.messageService.save(this.contactObj).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message)
        this.router.navigate(['/']).then(() => {
          window.scrollTo(0, 0);
        }); return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }

}
