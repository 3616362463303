<div class="login">
    @if(showFirst){
        <form (submit)="f.valid" class="form" #f="ngForm">
            <div class="header-login">
                <span class="title">ZABORAVIO SI LOZINKU?</span>
                <span class="content">
                    Ako ste zaboravili lozinku, unesite e-mail vašeg naloga. Nakon potvrde, dobićete kod kojim možete resetovati svoju šifru. Ukoliko imate problema sa promenom šifre, slobodno kontaktirajte.
                </span>
            </div>
    
            <label class="labelPopup">EMAIL</label>
            <input class="form-control" name="email" [(ngModel)]="obj.email" #email="ngModel" required autocomplete="off"
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"  />
            @if(f.submitted && email.invalid){
            <div class="form-control-error">Email je obavezno polje</div>
            }
            <br>
            <button type="submit" class="button-sucess" [disabled]="!f.valid" (click)="resetPassword()">
                <img class="only-icon" src="../../../../assets/icons/login.svg" />RESTARTUJ LOZINKU
            </button>
            <br><br>
            <span class="register">Ipak se sećate lozinke? Prijavi se na stranici za <span class="click" (click)="navigate('login')">prijavu</span>!</span>
            <span class="register">Možda nemate nalog? Registrujte se brzo i jednostavno na stranici <span class="click" (click)="navigate('register')">registracije</span>!</span>
        </form>
    }
    @else{
        <form (submit)="f.valid" class="form" #f="ngForm">
            <div class="header-login">
                <span class="title">OPORAVI LOZINKU</span>
                <span class="content">
                    Proverite email sandruče, gde smo Vam poslali kod za obnovu lozinke. Unesite kod i novu lozinku i nastavite sa korišćenjem sistema.
                </span>
            </div>
    
            <label class="labelPopup">CODE</label>
            <input class="form-control" name="code" [(ngModel)]="obj.code" #code="ngModel" required autocomplete="off"
                />
            @if(f.submitted && code.invalid){
            <div class="form-control-error">Code je obavezno polje</div>
            }
            <br>
            <div class="element">
                <label class="labelPopup">NOVA LOZINKA</label>
                <div class="password-wrapper">
                    <input [type]="passwordFieldType" class="form-control" name="password" [(ngModel)]="obj.password"
                        #password="ngModel" required autocomplete="off" />
                    <span class="toggle-password" (click)="togglePasswordVisibility()">
                        <img [src]="passwordFieldType === 'password' ? '../../../../assets/icons/passwordshow.svg' : '../../../../assets/icons/passwordhide.svg'"
                            alt="Toggle Password" />
                    </span>
                </div>
                @if(f.submitted && password.invalid){
                <div class="form-control-error">Lozinka je obavezno polje</div>
                }
            </div>
            <br>
            <button type="submit" class="button-sucess" [disabled]="!f.valid" (click)="resetPasswordConfirm()">
                <img class="only-icon" src="../../../../assets/icons/login.svg" />RESTARTUJ LOZINKU
            </button>
            <br><br>
            <span class="register">Ipak se sećate lozinke? Prijavi se na stranici za <span class="click" (click)="navigate('login')">prijavu</span>!</span>
            <span class="register">Možda nemate nalog? Registrujte se brzo i jednostavno na stranici <span class="click" (click)="navigate('register')">registracije</span>!</span>
        </form>
    }
    
</div>
