<div class="row">
<div class="column">
    <div class="subrow"><img src='../../../../../assets/icons/email.svg'/><span class="title"><a href="mailto:info@cuburac.rs">info&#64;cuburac.rs</a></span>
    </div>
    <div class="subrow"><img src='../../../../../assets/icons/phoneblack.svg'/><a href="tel:+381606644604">+381606644604</a></div>
    <div class="subrow"><img src='../../../../../assets/icons/mapblack.svg'/><a href="https://www.google.com/maps/@44.7948495,20.4782596,0a,82.2y,113.28h,90t/data=!3m4!1e1!3m2!1sZG1KYguVjeJ9hz_MDqscdA!2e0?source=apiv3&hl=sr">Maksima Gorkog 59, Beograd</a></div>
</div>
<div class="column mid">
    <span>
        <B>INFORMACIJE O FIRMI</B><br>
        PIB: <b>114445680</b><br>
        MB: <b>22024876</b><br>
        Delatnost: <b>7120</b><br>
    </span>
</div>
<div class="column">
    <div class="subrow">
        <img src='../../../../../assets/icons/facebook.svg'/><a href="">fb.com/cuburac</a>
    </div>
    <div class="subrow">
        <img src='../../../../../assets/icons/instagram.svg'/><a href="">&#64;cuburac</a>
    </div>
</div>
</div>
<div class="footer">
    <p>COPYRIGHT © 2024 AC Čuburac</p>
</div>