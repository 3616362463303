import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  @Input() currentPage: number = 1;
  @Input() pageSize: number = 0;
  @Input() count: number = 0;
  @Output() pageChange = new EventEmitter();
  
  public isLastPage : boolean = false;
  lastPageNum: number = 0;

  ngOnChanges(): void {
    if(this.count){
      this.lastPageNum = Math.ceil(this.count / this.pageSize);
      this.isLastPage = this.currentPage == this.lastPageNum;
    }
  }

  changePage(pagenum: number) {
    this.currentPage += pagenum;
    this.pageChange.emit(this.currentPage);
  }
}
