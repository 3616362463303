import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../config/config';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  private pathApi = this.config.setting['pathApi'] + "vehicle/";
  private jwtService = new JwtHelperService();

  constructor(private config: AppConfig, private http: HttpClient, private toastr: ToastrService) { }

  getAll(obj : any) {
    return this.http.post(this.pathApi + 'getAll', obj);
  }
  save(obj : any) {
    return this.http.post(this.pathApi + 'save', obj);
  }
  delete(id : number) {
    return this.http.delete(this.pathApi + 'delete/'+id);
  }
  getStats() {
    return this.http.get(this.pathApi + 'getMyStats');
  }
  get(id:string) {
    return this.http.get(this.pathApi + 'get/'+id);
  }
  getForOptions() {
    return this.http.get(this.pathApi + 'getForOptions');
  }
}
