import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './components/admin/admin-layout/admin-layout.component';
import { AuthGuard } from './services/guards/auth.guard';
import { AdminGuard } from './services/guards/admin.guard';
import { AdminDashboardComponent } from './components/admin/admin-dashboard/admin-dashboard.component';
import { PrivateDashboardComponent } from './components/private/private-dashboard/private-dashboard.component';
import { PrivateLayoutComponent } from './components/private/private-layout/private-layout.component';
import { LoginComponent } from './components/public/login/login.component';
import { VehicleManagerComponent } from './components/private/vehicle-manager/vehicle-manager.component';
import { ReferalComponent } from './components/private/referal/referal.component';
import { PublicLayoutComponent } from './components/public/layout/public-layout/public-layout.component';
import { HomeComponent } from './components/public/pages/home/home.component';
import { KalulatorRegistracijeComponent } from './components/public/pages/kalulator-registracije/kalulator-registracije.component';
import { AboutUsComponent } from './components/public/pages/about-us/about-us.component';
import { TehnickiPregledComponent } from './components/public/pages/tehnicki-pregled/tehnicki-pregled.component';
import { RegistracijaVozilaComponent } from './components/public/pages/registracija-vozila/registracija-vozila.component';
import { DokumentacijaComponent } from './components/public/pages/dokumentacija/dokumentacija.component';
import { OstaleUslugeComponent } from './components/public/pages/ostale-usluge/ostale-usluge.component';
import { IspitivanjeVozilaComponent } from './components/public/pages/ispitivanje-vozila/ispitivanje-vozila.component';
import { ServisVozilaComponent } from './components/public/pages/servis-vozila/servis-vozila.component';
import { ResetPasswordComponent } from './components/public/reset-password/reset-password.component';
import { RegisterComponent } from './components/public/register/register.component';
import { AktivirajNalogComponent } from './components/public/register/aktiviraj-nalog/aktiviraj-nalog.component';
import { OnlinezakazivanjeComponent } from './components/public/pages/onlinezakazivanje/onlinezakazivanje.component';
import { OsiguranjeComponent } from './components/public/pages/osiguranje/osiguranje.component';
import { UsermanagementComponent } from './components/private/usermanagement/usermanagement.component';
import { TerminiComponent } from './components/private/termini/termini.component';
import { BloglistComponent } from './components/public/blog/bloglist/bloglist.component';
import { BlogComponent } from './components/public/blog/blog/blog.component';
import { AddblogComponent } from './components/public/blog/addblog/addblog.component';
import { KontaktComponent } from './components/public/pages/kontakt/kontakt.component';
import { VehicleCrudComponent } from './components/private/vehicle-manager/vehicle-crud/vehicle-crud.component';

export const routes: Routes = [
    {
        path: '',
        component: PublicLayoutComponent,
        children: [
            {
                path: "", 
                component: HomeComponent 
            },
            {
                path: "kalkulator-registracije", 
                component: KalulatorRegistracijeComponent 
            },
            {
                path: "login", 
                component: LoginComponent 
            },
            {
                path: "online-zakazivanje", 
                component: OnlinezakazivanjeComponent 
            },
            {
                path: "osiguranje", 
                component: OsiguranjeComponent 
            },
            {
                path: "aktiviraj-nalog/:email/:code", 
                component: AktivirajNalogComponent 
            },
            {
                path: "register", 
                component: RegisterComponent 
            },
            {
                path: "zaboravio-sam-lozinku", 
                component: ResetPasswordComponent 
            },
            {
                path: "o-nama", 
                component: AboutUsComponent 
            },
            {
                path: "tehnicki-pregled", 
                component: TehnickiPregledComponent 
            },
            {
                path: "registracija-vozila", 
                component: RegistracijaVozilaComponent 
            },
            {
                path: "dokumentacija", 
                component: DokumentacijaComponent 
            },
            {
                path: "servis-vozila", 
                component: ServisVozilaComponent 
            },
            {
                path: "ispitivanje-vozila", 
                component: IspitivanjeVozilaComponent 
            },
            {
                path: "ostale-usluge", 
                component: OstaleUslugeComponent 
            },
            {
                path: "blog/:url", 
                component: BlogComponent 
            },
            {
                path: "kontakt", 
                component: KontaktComponent 
            },
            {
                path: "blogovi", 
                component: BloglistComponent 
            }
        ]
    },
    {
        path: 'panel',
        component: PrivateLayoutComponent,
        children: [
            {
                path: "blogovi", 
                canActivate: [AdminGuard],
                component: BloglistComponent
            },
            {
                path: "dodaj-blog", 
                canActivate: [AdminGuard],
                component: AddblogComponent
            },
            {
                path: "termini", 
                canActivate: [AuthGuard],
                component: TerminiComponent
            },
            {
                path: "kalkulator-registracije",
                canActivate: [AuthGuard],
                component: KalulatorRegistracijeComponent 
            },
            {
                path: "vozila", 
                canActivate: [AuthGuard],
                component: VehicleManagerComponent
            },
            {
                path: "vozila/dodaj/:id", 
                component: VehicleCrudComponent 
            },
            {
                path: "referal", 
                canActivate: [AuthGuard],
                component: ReferalComponent
            }
        ]
    }    
];