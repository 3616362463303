import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ispitivanje-vozila',
  standalone: true,
  imports: [],
  templateUrl: './ispitivanje-vozila.component.html',
  styleUrl: './ispitivanje-vozila.component.scss'
})
export class IspitivanjeVozilaComponent {
  constructor(private router : Router, private toastr : ToastrService ){
    this.router.navigate(['/']);
    this.toastr.warning('Uskoro više informacija o ispitivanju vozila.');
  }
}
