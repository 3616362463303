import { Component } from '@angular/core';

@Component({
  selector: 'app-osiguranje',
  standalone: true,
  imports: [],
  templateUrl: './osiguranje.component.html',
  styleUrl: './osiguranje.component.scss'
})
export class OsiguranjeComponent {

}
