import { Component } from '@angular/core';

@Component({
  selector: 'app-tehnicki-pregled',
  standalone: true,
  imports: [],
  templateUrl: './tehnicki-pregled.component.html',
  styleUrls: ['./tehnicki-pregled.component.scss', './tehnicki-pregled-mobile.component.scss']

})
export class TehnickiPregledComponent {

}
