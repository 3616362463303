import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-side-private-mobile-menu',
  standalone: true,
  imports: [],
  templateUrl: './side-private-mobile-menu.component.html',
  styleUrl: './side-private-mobile-menu.component.scss',
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(143%, 0, 0)'
      })),
      transition('in => out', animate('900ms ease-in-out')),
      transition('out => in', animate('900ms ease-in-out'))
    ]),
  ]
})
export class SidePrivateMobileMenuComponent {
  @Input() isVisible: boolean = false;
  @Output() togleModal = new EventEmitter<void>();
  @Input() animationState = 'out';

  constructor(private router:Router, private authService: AuthService){

  }
  isAdmin() {
    return this.authService.isRole('Admin')
  }
  ngOnChanges() {
    this.animationState = this.isVisible ? 'in' : 'out';
  }
  logout(){
    this.authService.logout();
  }
  navigateId(route : string, element: string){
    this.togleModal.emit()
    this.authService.clickScroll(route, element)
  }

  isLoggedIn(){
    return this.authService.isLoggedIn()
  }

  handleClick(): void {
    this.togleModal.emit()
  }
  navigate (route : string)
  {
    this.togleModal.emit()
this.router.navigate([route]).then(() => {
    window.scrollTo(0, 0);
});
  }
}
