<div class="banner">
    <span class="title">Registracija vozila</span>
    <span class="content">Registracijom vozila u našem auto centru, završavate sve na jednom mestu i u jednom
        danu.</span>
</div>
<div class="section">
    <div class="element" id="izdavanjenalepnica">
        <div class="element-image"
            style="background-image: url('../../../../../assets/images/registracija/registraciona-nalepnica.jpg');">
        </div>
        <div class="element-content">
            <span class="title">Izdavanje nalepnica</span>
            <span class="content">Dodatno opterećenje prilikom registracije je i odlazak u MUP po nalepnicu. Postarali
                smo se da i taj deo posla završite u našem centru. Brzo, efikasno i sve na jednom mestu je utisak koji
                želimo da poneseta kada završite sa registracijom u “Čuburcu”. </span>

        </div>
    </div>
    <div class="element" id="izdavanjeosaobracajneitablica">
        <div class="element-image"
        style="background-image: url('../../../../../assets/images/registracija/banner-registracija.jpg');">
    </div>
        <div class="element-content">
            <span class="title">Izdavanje tablica i saobraćajne dozvole</span>
            <span class="content">Pored stručnog osoblja koje brine o vašim vozilima, naš tim čine i pravnici koji se
                mogu pobrinuti o svim pravnim poslovima koje treba da završite. Bilo da treba da odjavite tablice,
                uradite redovnu godišnju registraciju vozila ili prvi put registrujete vozilo, promenite ili produžite
                saobraćajnu dozvolu naš tim može to učiniti umesto vas.</span>
        </div>
    </div>
    <div class="element" id="nacinplacanja">
        <div class="element-image"
        style="background-image: url('../../../../../assets/images/registracija/nacin-placanja.jpg');">
        </div>
        <div class="element-content">
            <span class="title">Način placanja</span>
            <span class="content">Trudili smo se da vam olakšamo svaki korak tokom registracije vozila, zato su vam na raspolaganju mogućnosti plaćanja:
                <ul>
                    <li>Gotovinom</li>
                    <li>Čekovima građana (do 6 mesečnih rata bez naknade i kamate)</li>
                    <li>Plaćanje platnim karticama na POS terminalu (Visa i Maestro kao i MasterCard i DinaCard)</li>
                    <li>Putem administrativne zabrane</li>
                    <li>Sve takse za registraciju vozila ili pravnih poslova možete uplatiti u našem centru. Naše mesto platnog prometa vam daje mogućnost brzog i lakog regulisanja svih nadoknada koje vas tokom registracije vozila u pitanju.</li>
                </ul></span>
        </div>
    </div>
    <div class="element" id="osiguranje" (click)="navigate('osiguranje')">
        <div class="element-image"
        style="background-image: url('../../../../../assets/images/registracija/osiguranjevozizla.webp');">
        </div>
        <div class="element-content">
            <span class="title">Osiguranje</span>
            <span class="content">Osiguranje može delovati komplikovano, ali uz AC Čuburac, pronalaženje najbolje ponude
                nikada nije bilo lakše. Naša platforma štedi vam vreme i novac tako što jednostavno i besplatno
                pretražuje sve dostupne opcije, bilo kada i bilo gde. Uz nas, kupovina osiguranja postaje brza i
                jednostavna – uporedite cene, odaberite ponudu i osigurajte se online, uz podršku svih vodećih
                osiguravajućih kompanija u Srbiji, dok mi ostajemo potpuno nezavisni u našem radu.</span>
        </div>
    </div>
    <div class="element" id="prenosvlasnistva">
        <div class="element-image"
        style="background-image: url('../../../../../assets/images/registracija/prenosvlasnistva.jpg');">
        </div>
        <div class="element-content">
            <span class="title">Prenos vlasništva</span>
            <span class="content">Prenos vlasništva za vozilo je važan korak prilikom kupovine ili prodaje automobila.
                Proces uključuje nekoliko ključnih koraka, kao što su overa ugovora o kupoprodaji, prijava promene
                vlasništva u nadležnoj službi i plaćanje odgovarajućih taksi i poreza. Nakon toga, novi vlasnik dobija
                saobraćajnu dozvolu sa svojim imenom i postaje zakonski odgovoran za vozilo. Kako bi sve prošlo glatko,
                važno je pažljivo pratiti sve korake i obezbediti svu potrebnu dokumentaciju na vreme.</span>
        </div>
    </div>
    <div class="element" id="zelenikarton">
        <div class="element-image"
        style="background-image: url('../../../../../assets/images/registracija/zelenikarton.jpg');">
        </div>
        <div class="element-content">
            <span class="title">Zeleni karton</span>
            <span class="content">Zeleni karton je "pasoš" vašeg motornog vozila za putovanje u inostranstvo. Ovaj
                međunarodni karton osiguranja dokazuje da imate važeće osiguranje od autoodgovornosti i neophodan je
                prilikom putovanja u određene zemlje. Izdaje se uz polisu obaveznog osiguranja i važi koliko i sama
                polisa.
                <br>
                <br>
                Zeleni karton je obavezan za ulazak vozilom u sledeće zemlje: Rusija, Belorusija, Ukrajina, Moldavija,
                Turska, Izrael, Iran, Severna Makedonija, Albanija, BiH, Tunis, Azerbejdžan i Maroko. Uz naš zeleni
                karton, osigurani ste za eventualne štete koje možete prouzrokovati trećim licima u saobraćajnoj nezgodi
                u ovim zemljama.
                <br>
                <br>

                Napominjemo da zeleni karton nije potreban za ulazak u Crnu Goru, zahvaljujući posebnom bilateralnom
                sporazumu sa Srbijom. Za dobijanje zelenog kartona potrebno je priložiti polisu obaveznog osiguranja i
                saobraćajnu dozvolu.</span>
        </div>
    </div>