import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlogService } from '../../../../services/blog.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss'
})
export class BlogComponent {
  constructor(private route: ActivatedRoute, private router: Router, private toastr:ToastrService, private blogService:BlogService) { }
  blog: any; 

  ngOnInit(): void {
    this.blogService.getByUrl(this.route.snapshot.paramMap.get('url')!).subscribe((response: any) => {
      if (response.status == 200) {
        this.blog = response.data;
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
}
