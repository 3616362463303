import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-registracija-vozila',
  standalone: true,
  imports: [],
  templateUrl: './registracija-vozila.component.html',
  styleUrls: ['./registracija-vozila.component.scss', './registracija-vozila-mobile.component.scss']
})
export class RegistracijaVozilaComponent {
  constructor(private router: Router, private authService: AuthService) {

  }
  navigate(route: string) {
    this.router.navigate([route]).then(() => {
      window.scrollTo(0, 0);
    });
  }
  navigateId(route: string, element: string) {
    this.authService.clickScroll(route, element)
  }
}
