import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { VehicleService } from '../../../services/vehicle.service';
import { VehicleCrudComponent } from './vehicle-crud/vehicle-crud.component';
import { VehicleDataOut } from '../../../models/vehicle.model';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from '../pagination/pagination.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-vehicle-manager',
  standalone: true,
  imports: [VehicleCrudComponent, CommonModule, PaginationComponent],
  templateUrl: './vehicle-manager.component.html',
  styleUrls: ['./vehicle-manager.component.scss', './vehicle-manager-mobile.component.scss', './vehicle-manager-tablet.component.scss']
})
export class VehicleManagerComponent implements OnInit {
  pageData = {
    pageSize: 8,
    currentPage: 1,
    search: ''
  }
  @ViewChild(VehicleCrudComponent) popupComponentRef!: VehicleCrudComponent;
  @ViewChild(PaginationComponent) paginationComponentRef!: PaginationComponent;
  data: VehicleDataOut[] = [];
  count: number = 0;
  stats: any = {
    nextAppointment: '',
    vehicleCount: 0,
    unusedReferals: 0,
    usedReferals: 0,
    pendingReferals: 0
  };

  constructor(private vehicleService: VehicleService,private router: Router, private toastr: ToastrService, private authService: AuthService) {

  }
  isAdmin(){
    return this.authService.isRole('Admin')
  }
  ngOnInit(): void {
    this.reloadTable();
  }

  remove(id: number) {
    const confirmed = window.confirm("Da li ste sigurni da želite da obrišete ovo vozilo?");
    if (!confirmed) {
      return;
  }
    this.vehicleService.delete(id).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.reloadTable();
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }

  reloadTable() {
    this.getStats();
    this.vehicleService.getAll(this.pageData).subscribe((response: any) => {
      if (response.status == 200) {
        this.data = response.data.data;
        this.count = response.data.count;
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
  getStats() {
    this.vehicleService.getStats().subscribe((response: any) => {
      if (response.status == 200) {
        this.stats = response.data;
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
  pageChange(value: number) {
    this.pageData.currentPage = value
    this.reloadTable()
  }

  selectUser(id?: any) {
    this.router.navigate(['panel/vozila/dodaj/'+id])
  }
}