import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss','reset-password-mobile.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public showFirst : boolean = true;
  public obj = {
    email: '',
    password: '',
    code: ''
  }
  passwordFieldType: string = 'password';

  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router) {

  }
  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['panel/vozila']);
    }
  }
  navigate (route : string)
  {
this.router.navigate([route]).then(() => {
    window.scrollTo(0, 0);
});
  }

  togglePasswordVisibility(): void {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
  public resetPassword() {
    this.authService.resetPassword(this.obj.email).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message)
        this.showFirst = false;
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
  public resetPasswordConfirm() {
    this.authService.resetPasswordConfirm(this.obj).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message)
        this.showFirst = true;
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
    this.router.navigate(['login']);
  }

}
