import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../config/config';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private pathApi = this.config.setting['pathApi'] + "message/";

  constructor(private config: AppConfig, private http: HttpClient, private toastr: ToastrService, private router: Router) { }

  save(obj: any) {
    return this.http.post(this.pathApi + 'save', obj);
  }
  getAll(data: any) {
    return this.http.post(this.pathApi + 'getAll', data);
  }
}
