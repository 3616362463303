<form (submit)="f.valid" class="form" #f="ngForm">
    <label class="labelPopup">Ime i prezime</label>
    <input class="form-control" name="name" [(ngModel)]="contactObj.name" #name="ngModel" required
        autocomplete="off" />
    @if(f.submitted && name.invalid){
    <div class="form-control-error">Polje ime i prezime je obavezno.</div>
    }
    <br>
    <label class="labelPopup">Email</label>
    <input class="form-control" name="email" [(ngModel)]="contactObj.email" #email="ngModel" required
        autocomplete="off" />
    @if(f.submitted && email.invalid){
    <div class="form-control-error">Email je obavezno polje.</div>
    }
    <br>
    <label class="labelPopup">Telefon</label>
    <input class="form-control" name="phone" [(ngModel)]="contactObj.phone" #phone="ngModel" required
        autocomplete="off" />
    @if(f.submitted && phone.invalid){
    <div class="form-control-error">Telefon je obavezno polje.</div>
    }
    <br>
    <label class="labelPopup">Poruka</label>
    <textarea rows="5" class="form-control" name="message" [(ngModel)]="contactObj.messageContent" #message="ngModel" required
        autocomplete="off">
        </textarea>
    @if(f.submitted && message.invalid){
    <div class="form-control-error">Poruka je obavezno polje.</div>
    }
    <br>
    <button type="submit" class="button-light" (click)="sendMessage()">POŠALJI PORUKU</button>
</form>