import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VehicleService } from '../../../../services/vehicle.service';
import { AppointmentService } from '../../../../services/appointment.service';
import { ShedulingData } from '../../../../models/schedule.model';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-onlinezakazivanje',
  standalone: true,
  imports: [FormsModule, CommonModule, NgbModule],
  templateUrl: './onlinezakazivanje.component.html',
  styleUrl: './onlinezakazivanje.component.scss'
})
export class OnlinezakazivanjeComponent {
  showShadow = false;
  termini: Date[] = [];
  myVehicles: any[] = [];
  public data = new ShedulingData();
  constructor(private toastr: ToastrService, private vehicleService: VehicleService, private authService: AuthService, private appointmentService: AppointmentService, private router: Router) {


  }
  minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 15 };

  ngOnInit(): void {
    this.getVehicles();
  }
  changeState(number: Number) {
    if (number === 1) {
      this.showShadow = true;
    }
    if (number === 2) {
      this.showShadow = false;
    }
  }
  navigate(url: string) {
    this.router.navigate([url]).then(() => {
      window.scrollTo(0, 0);
    });
  }

  getVehicles() {
    if (this.isLoggedIn()) {
      this.vehicleService.getForOptions().subscribe((response: any) => {
        if (response.status == 200) {
          this.myVehicles = response.data;
        }
        else {
          this.toastr.error(response.message);
          return;
        }
      }, (error: any) => {
        this.toastr.error('Došlo je do greške.');
      });
    }
    else {
      this.myVehicles = [];
    }
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }


  save() {
    if (this.isLoggedIn()) {
      if (this.data.VehicleId == 0 || this.data.VehicleId == undefined) {
        this.toastr.error('Molimo odaberite vozilo.')
        return;
      }
      if (this.data.tempdatum.getFullYear() < 2024) {
        this.toastr.error('Molimo odaberite datum.')
        return;
      }
      if (this.data.tempdatum.getHours() == 0) {
        this.toastr.error('Molimo odaberite termin.')
        return;
      }
    }
    else {
      if (this.data.lastName == '' || this.data.firstName == '') {
        this.toastr.error('Molimo unesite ime i prezime.')
        return;
      }
      if (this.data.email != '') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(this.data.email);

        if (!isValidEmail) {
          {
            this.toastr.error('Molimo unesite ispravan email adresu.')
            return;
          }
        }
      }
      else {
        this.toastr.error('Molimo unesite email adresu.')
        return;
      }
      if (this.data.phone != '') {
        const phoneRegex = /^[+]?[\d\s-]{7,15}$/;
        const isValidPhone = phoneRegex.test(this.data.phone);

        if (!isValidPhone) {
          this.toastr.error('Molimo unesite broj telefona.')
          return;
        }
      }
      else {
        this.toastr.error('Molimo unesite broj telefona.')
        return;
      }
      if (this.data.tipVozila == 0) {
        this.toastr.error('Molimo izaberite tip vozila.')
        return;
      }
      if (this.data.licensePlate == '' || this.data.licensePlate.length > 15) {
        this.toastr.error('Molimo unesite ispravnu registracionu oznaku.')
        return;
      }
      if (this.data.tempdatum.getFullYear() < 2024) {
        this.toastr.error('Molimo odaberite datum.')
        return;
      }
      if (this.data.tempdatum.getHours() == 0) {
        this.toastr.error('Molimo odaberite termin.')
        return;}
    }
    this.data.datum=this.data.tempdatum;
    this.appointmentService.save(this.data).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message)
        this.router.navigate(['/']).then(() => {
          window.scrollTo(0, 0);
        }); return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }

  onDateChange(event: any) {
    this.data.tempdatum = new Date(event.year, event.month-1, event.day+1);
    this.appointmentService.getFreeForDate(this.data.tempdatum).subscribe((response: any) => {
      if (response.status == 200) {
        this.termini = response.data.map((dateString: string) => new Date(dateString));
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
  selectAppointment(appoint: Date) {
    this.data.tempdatum = appoint;
  }
}
