<div class="banner">
    <span class="title">Online zakazivanje</span>
    <span class="content">Na raspolaganju su vam različiti paketi, a pored naznačenih rizika iz paketa možete izabrati i
        dodatna pokrića.</span>
</div>
<div class="zakazivanje">
    <div class="zakazivanje-body">
        <form (submit)="f.valid" class="form col-md-12" #f="ngForm">
            <div class="form-row">
                

                @if(isLoggedIn()){
                    <div class="form-col-50"><label class="labelPopup">Vozilo</label>
                        <select class="form-control"name="tip" [(ngModel)]="data.VehicleId" #tip="ngModel" required
                            autocomplete="off">
                            <option *ngFor="let vehicle of myVehicles" [ngValue]="vehicle.key">{{ vehicle.value }}</option>
                        </select>
        
                        <div *ngIf="f.submitted && tip.invalid" class="form-control-error">Vozilo je obavezno polje.
                        </div>
                    </div>
                    <div class="form-col-50"><label>Izaberite neko od vaših vozila. Ukoliko želite da dodate novo vozilo na Vaš nalog možete ga dodati na stranici <span (click)="navigate('panel/vozila')" style="color:#3939f4; text-decoration: underline; cursor: pointer;"><i>Moja vozila</i></span>.</label>
                    </div>
                }
                @else {
                    <div class="form-col-50">
                        <label class="labelPopup">Ime</label>
                        <input class="form-control" name="firstName" [(ngModel)]="data.firstName" #firstName="ngModel" autocomplete="off" />
                        <div *ngIf="f.submitted && firstName.invalid" class="form-control-error">Ime je obavezno polje.</div>
                    </div>
                    <div class="form-col-50">
                        <label class="labelPopup">Prezime</label>
                        <input class="form-control" name="lastName" [(ngModel)]="data.lastName" #lastName="ngModel" autocomplete="off" />
                        <div *ngIf="f.submitted && lastName.invalid" class="form-control-error">Prezime je obavezno polje</div>
                    </div>
    
                    <div class="form-col-50">
                        <label class="labelPopup">Email</label>
                        <input class="form-control" name="email" [(ngModel)]="data.email" #email="ngModel" autocomplete="off" />
                        <div *ngIf="f.submitted && email.invalid" class="form-control-error">Email je obavezno polje.
                        </div>
                    </div>
                    <div class="form-col-50">
                        <label class="labelPopup">Telefon</label>
                        <input class="form-control" name="Phone" [(ngModel)]="data.phone" #Phone="ngModel"
                            autocomplete="off" />
                        <div *ngIf="f.submitted && Phone.invalid" class="form-control-error">Telefon je obavezno
                            polje</div>
                    </div>
                    <div class="form-col-50"><label class="labelPopup">Tip vozila</label>
                        <select class="form-control" name="tip" [(ngModel)]="data.tipVozila" #tip="ngModel"
                            autocomplete="off">
                            <option [ngValue]="0">Tip vozila</option>
                            <option [ngValue]="1">Putnicko vozilo</option>
                            <option [ngValue]="2">Motor/Skuter</option>
                        </select>
        
                        <div *ngIf="f.submitted && tip.invalid" class="form-control-error">Tip vozila je obavezno polje.
                        </div>
                    </div>
                    <div class="form-col-50"><label class="labelPopup">Registraciona oznaka</label>
                        <input class="form-control" name="lplate" [(ngModel)]="data.licensePlate" #lplate="ngModel"
                            autocomplete="off" />
                        <div *ngIf="f.submitted && lplate.invalid" class="form-control-error">Registraciona oznaka je
                            obavezno
                            polje.</div>
                    </div>
                }
                <div class="form-col-textarea">
                    <label class="labelPopup">Komentar</label>
                    <textarea class="form-control" name="comment"  #comment="ngModel" [(ngModel)]="data.comment"></textarea>
                </div>
            </div>
            <label class="labelPopup" style="padding-top: 15px;">Termin</label>
            <div class="termin-row">
                <div class="datum-picker">
                    <ngb-datepicker #dp name="datum" [(ngModel)]="data.datum" 
                        [minDate]="minDate" [maxDate]="maxDate" (ngModelChange)="onDateChange($event); $event.stopPropagation()" #datum="ngModel"
                        required style="width: 100%;"></ngb-datepicker>
                </div>
                <div class="termini">
                    <div class="appointment" *ngFor="let termin of termini" (click)="selectAppointment(termin)"
                        [ngClass]="{'selected': data.tempdatum == termin}">
                        {{ termin.getHours().toString().padStart(2, '0') }}:{{ termin.getMinutes().toString().padStart(2,
                        '0') }} - {{ (termin.getHours() + 1).toString().padStart(2, '0') }}:{{
                        termin.getMinutes().toString().padStart(2, '0') }}
                    </div>
                </div>
            </div>
            <div *ngIf="f.submitted && datum.invalid" class="form-control-error">Datum nije ispravan ili niste
                oznacili termin.
            </div>
            <br>
        </form>
        <button class="button-sucess" [disabled]="!f.valid" (click)="save()"><img
                src='../../../../assets/icons/submit.svg' />ZAKAŽI TERMIN</button>
    </div>
</div>