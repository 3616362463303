import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-aktiviraj-nalog',
  standalone: true,
  imports: [],
  templateUrl: './aktiviraj-nalog.component.html',
  styleUrl: './aktiviraj-nalog.component.scss'
})
export class AktivirajNalogComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private toastr:ToastrService, private authService:AuthService) { }

  ngOnInit(): void {
    this.authService.activate(this.route.snapshot.paramMap.get('email')!, this.route.snapshot.paramMap.get('code')!).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message)
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
    this.router.navigate(['login']);
  }
}