<div class="banner">
  <span class="title">BLOG</span>
</div>
<div class="blogrow">
  <div *ngFor="let blog of blogs" class="item" (click)="navigateBlog(blog.url)">
    <div class="item-part" style="background-image: url({{blog.image}})">
    </div>
    <div class="item-part-down">
      <h1 class="title">{{ blog.naslov }}</h1>
    </div>
  </div>
</div>
<div class="pagination-row">
<div class="pagination-inner">
  <app-pagination [pageSize]="pageData.pageSize" [currentPage]="pageData.currentPage" [count]="count"
(pageChange)="pageChange($event)">
</app-pagination>
</div>
</div>