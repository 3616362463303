import { CommonModule,Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PublicMenuComponent } from './public-menu/public-menu.component';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { SideMobileMenuComponent } from './side-mobile-menu/side-mobile-menu.component';

@Component({
  selector: 'app-public-header',
  standalone: true,
  imports: [PublicMenuComponent, SideMobileMenuComponent],
  templateUrl: './public-header.component.html',
  styleUrl: './public-header.component.scss',
})
export class PublicHeaderComponent implements OnInit {
  ngOnInit(): void {
  }
  constructor(private router:Router, private authService: AuthService){

  }

  navigateId(route : string, element: string){
    this.authService.clickScroll(route, element)
  }
  
  modalVisible: boolean = false;
  navigate (route : string)
  {
this.router.navigate([route]).then(() => {
    window.scrollTo(0, 0);
});
  }
  togleModal() {
    this.modalVisible = !this.modalVisible;
    console.log(this.modalVisible,'isvisible')
  }
  openTab(num : number) {
    this.router.navigate(['online-zakazivanje'])
    // this.popupComponentRef.changeState(num);
  }

  isLoggedIn(){
    return this.authService.isLoggedIn();
  }
}
