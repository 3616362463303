import { ApplicationConfig } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { AppConfig } from './config/config';
import { provideToastr } from 'ngx-toastr';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { StandardErrorInterceptor } from './interceptors/standard-error-interceptor';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors([
      AuthInterceptor,
      StandardErrorInterceptor,
    ])),
    NgxUiLoaderService,
    AppConfig,
    FormsModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    provideToastr(),
    provideAnimations(),
  ]
};
