<form [formGroup]="blogForm" (ngSubmit)="submitContent()">
  <div class="row">
    <label class="labelPopup">Naslov</label>
    <input class="form-control" formControlName="title" autocomplete="off" />
    <div *ngIf="blogForm.get('title')?.invalid && blogForm.get('title')?.touched" class="form-control-error">
      Naslov je obavezno polje.
    </div>
  </div>

  <div class="row">
    <label class="labelPopup">Sadržaj</label>
    <quill-editor formControlName="content"></quill-editor>
    <div *ngIf="blogForm.get('content')?.invalid && blogForm.get('content')?.touched" class="form-control-error">
      Sadržaj je obavezno polje.
    </div>
  </div>

  <div class="row">
    <label class="labelPopup">Slika URL</label>
    <input class="form-control" formControlName="image" autocomplete="off" />
    <div *ngIf="blogForm.get('image')?.invalid && blogForm.get('image')?.touched" class="form-control-error">
      Slika je obavezno polje.
    </div>
  </div>

  <div class="row">
    <button type="submit">Submit</button>
  </div>
</form>
