import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PublicFooterComponent } from '../public-footer/public-footer.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { PublicHeaderComponent } from '../public-header/public-header.component';

@Component({
  selector: 'app-public-layout',
  standalone: true,
  imports: [RouterModule, PublicHeaderComponent, PublicFooterComponent,LoadingBarHttpClientModule],
  templateUrl: './public-layout.component.html',
  styleUrl: './public-layout.component.scss'
})
export class PublicLayoutComponent {
  constructor(private router : Router)
  {
    
  }
  navigate(route: string) {
    this.router.navigate([route]).then(() => {
      window.scrollTo(0, 0);
    });
  }
}
