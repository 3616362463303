<div class="login">
    <div class="description">
        <span class="title">Šta je loyalty program?</span>
        <span class="text">Loyalty program Auto centra Čuburaca je jedinstveni program lojalnosti koji nagrađuje naše stalne korisnike brojnim pogodnostima. Uz obaveštenja o isteku registracije, obaveštenja o ekskluzivnim promocijama i specijalnim ponudama, naši članovi mogu ostvariti značajne popuste na sve naše usluge. Pridružite se našem loyalty programu i uživajte u vrhunskoj usluzi uz dodatne uštede i benefite. Iskoristite priliku da postanete deo našeg programa i osigurajte sebi brojne prednosti.</span> 
        <span class="title">Ko i kako ostvaruje pravo na popust?</span>
        <span class="text">Popuste možete ostvariti na nekoliko atraktivnih načina. Pozivom drugih korisnika putem našeg referral sistema, koji ostvaruju popust, dobijate kredite koje možete koristiti za dodatne popuste. Takođe, ako se redovno registrujete svake godine, automatski ostvarujete sve veće popuste. Iskoristite ove mogućnosti da maksimalno uštedite i uživate u ekskluzivnim pogodnostima našeg loyalty programa.</span>
        <span class="title">Kako obuhvata jos loyalty program?</span>
        <span class="text">Putem našeg loyalty programa možete pratiti i primati obaveštenja o isteku registracije vozila, zakazivati termine za tehničke preglede, pratiti status zakazanih termina i primati podsetnike za sve zakazane usluge. Ovaj program vam omogućava da jednostavno upravljate svojim vozilom i nikada ne propustite važne datume.</span>
        
    </div>
    <div class="form">
        <form (submit)="f.valid" #f="ngForm">
            <div class="header-login">
                <span class="title">PRIJAVA NA SISTEM</span>
                <span class="content">
                    Prijavite se na naš sistem, upravljajte svojim vozilima i pratite istoriju održavanja i registracije.
                    Pozovite prijatelje i ostvarite popuste u Auto Centru Čuburac.
                </span>
            </div>
    
            <label class="labelPopup">EMAIL</label>
            <input class="form-control" name="email" [(ngModel)]="user.email" #email="ngModel" required autocomplete="off"
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title="Invalid email address" />
            @if(f.submitted && email.invalid){
            <div class="form-control-error">Email je obavezno polje</div>
            }
            <br>
    
            <label class="labelPopup">LOZINKA</label>
            <div class="password-wrapper">
                <input [type]="passwordFieldType" class="form-control" name="password" [(ngModel)]="user.password"
                    #password="ngModel" required autocomplete="off" />
                <span class="toggle-password" (click)="togglePasswordVisibility()">
                    <img [src]="passwordFieldType === 'password' ? '../../../../assets/icons/passwordshow.svg' : '../../../../assets/icons/passwordhide.svg'" alt="Toggle Password" />
                </span>
            </div>
            @if(f.submitted && password.invalid){
            <div class="form-control-error">Lozinka je obavezno polje</div>
            }
    
            <span class="forgot-password" (click)="navigate('zaboravio-sam-lozinku')">Zaboravio si lozinku?</span>
            <br><br>
    
            <button type="submit" class="button-sucess" [disabled]="!f.valid" (click)="login()">
                <img class="only-icon" src="../../../../assets/icons/login.svg" />PRIJAVI SE
            </button>
            <br><br>
            <span class="register">Nemate nalog? Registrujte se brzo i jednostavno na stranici <span class="click" (click)="navigate('register')">registracije</span>!</span>
        </form>
        
    </div>
    
</div>
