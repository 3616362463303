<div class="banner">
    <span class="title">Osiguranje</span>
    <span class="content">Na raspolaganju su vam različiti paketi, a pored naznačenih rizika iz paketa možete izabrati i
        dodatna pokrića.</span>
</div>

<div class="section">
    <div class="content-row">
        <div class="content-column1"
            style="background-image: url('../../../../../assets/images/osiguranje/autoodgovornost.jpg');">

        </div>
        <div class="content-column2">
            <span class="title">Osiguranje od autoodgovornosti</span>
            <span class="text">Odgovornost prema sebi i drugima!
                Neizvesnost je ono što nas čini jako obazrivim kada je priprema za put u pitanju. Koliko god mislili da
                imate sve pokriveno, mnogo je okolnosti koje ne možemo da kontrolišemo i čiji su ishodi jako neprijatni
                i stresni. Zato se postarajte da te ishode olakšate, najvažnije je da uz osnovna vozačka dokumenta, sa
                sobom uvek imate polisu osiguranja od autoodgovornosti.
                Osiguranje od autoodgovornosti je zakonski obavezno osiguranje koje se zaključuje prilikom registracije
                motornih vozila. Cilj je zaštita u slučaju štete pričinjene trećem licu, tako što će troškove koje bi
                inače platio osiguranik, platiti Generali osiguranje.</span>
        </div>
    </div>
    <div class="content-row">
        <div class="content-column2">
            <span class="title">Putno osiguranje - budite bezbedni uz nas</span>
            <span class="text">Tokom turističkog ili poslovnog boravka u inostranstvu, nepredviđeni zdravstveni problemi
                mogu vam poremetiti planove. Mi vam pomažemo da takve situacije prevaziđete.
                Ukoliko vam se na putovanju desi nesrećni slučaj, bolest, ili se ukaže potreba za neodložnom hirurškom
                intervencijom i boravkom u bolnici, uz našu polisu putnog osiguranja bićete adekvatno zbrinuti.</span>
        </div>
        <div class="content-column1"
        style="background-image: url('../../../../../assets/images/osiguranje/putnoosiguranje.jpg');">

        </div>
    </div>
    <div class="content-row">
        <div class="content-column1"
        style="background-image: url('../../../../../assets/images/osiguranje/pomocnaputu.webp');">

        </div>
        <div class="content-column2">
            <span class="title">Uz Generali pomoć na putu nikada ne putujete sami</span>
            <span class="text">Da li vam se bar jednom desilo da vas "auto ostavi na putu" usled nekih nepredviđenih
                okolnosti koje mogu izazvati tehnički problemi, kvar motornog vozila ili bilo kakav drugi nepredviđeni
                događaj? Pitali ste se kako bezbedno stići do prvog mesta na kome vam može biti ukazana pomoć.
                <br><br>
                Zato <b>„Pomoć na putu“</b> vam obezbeđuje i brzu i efikasnu asistenciju ukoliko dođe do nepredviđenih
                okolnosti koje mogu da utiču na vozače i vozila na putevima širom Srbije i Evrope.
                <br><br>
                U slučaju mehaničkog ili električnog kvara na vozilu, saobraćajne nezgode ili neke druge nezgode zbog
                koje osigurano vozilo ne može da se kreće (gubitak ključeva, nedostatak ili loš kvalitet goriva,
                izduvani pneumatici) dovoljno je da pozovete kontakt centar na broj telefona <b>+ 381 11 2453343</b> i biće
                vam pružena adekvatna pomoć u najkraćem mogućem roku.

                Servis Pomoć na putu obezbeđujemo u saradnji sa dugogodišnjim partnerom - asistentskom kompanijom Europ
                Assistance.<br><br>
                U ponudi su tri vrste paketa pomoći na putu:<br>
                <ul>
                    <li>Basic</li>
                    <li>Comfort</li>
                    <li>Elegant</li>
                </ul></span>
        </div>
    </div>
    <div class="content-row">
        <div class="content-column2">
            <span class="title">Potpuna zaštita vašeg vozila</span>
            <span class="text">Bilo da vaše motorno vozilo koristite za lične potrebe ili za potrebe posla kojim se
                bavite, poželjno je da ga obezbedite od potencijalnih nemilih događaja koji vam se mogu desiti na putu.
                Najbolja i najpouzdanija zaštita motornih vozila je upravo kasko osiguranje.
                Kasko osiguranje motornih vozila pruža osiguravajuću zaštitu od oštećenja ili potpunog uništenja vozila,
                usled različitih rizika.
                <br><br>Shodno vašim potrebama, nudimo vam tri obima pokrića kasko osiguranja:
                <ul>
                    <li>potpuno kasko osiguranje</li>
                    <li>dopunsko kasko osiguranje</li>
                    <li>delimično kasko osiguranje</li>
                </ul>

            </span>
        </div>
        <div class="content-column1"
        style="background-image: url('../../../../../assets/images/osiguranje/kasko1.png');">

        </div>
    </div>
    <div class="content-row">
        <div class="content-column1"
        style="background-image: url('../../../../../assets/images/osiguranje/kasko2.jpg');">

        </div>
        <div class="content-column2">
            <span class="title">Fleksi kasko - kasko osiguranje po vašoj meri!</span>
            <span class="text">Ukoliko je vaše putničko vozilo starije od 5 godina, na raspolaganju vam je Fleksi kasko
                u okviru kojeg je moguće odabrati:<br><br>
                <ul>
                    <li>Potpuno kasko osiguranje bez rizika krađe (osigurava vaše vozilo od najrazličitijih oštećenja
                        izazvanih saobraćajnom nezgodom, vremenskim nepogodama, požarom, eksplozijom, padom ili udarom
                        nekog
                        predmeta, obešću i zlonamernim postupcima trećih lica, manifestacijama ili demonstracijama)</li>
                    <li>Potpuno kasko osiguranje sa rizikom krađe vozila (pokriva i rizike provalne krađe, razbojničke
                        krađe i
                        razbojništva celog vozila ili njegovih delova)</li>
                    <li>Potpuno kasko osiguranje samo za rizik sudara, gde su štete i najčešće.</li>
                </ul>
            </span>
        </div>
    </div>
    <div class="content-row">
        <div class="content-column2">
            <span class="title">Delimično kasko osiguranje</span>
            <span class="text">Ugovara se u okviru polise kasko osiguranja. Zaključivanjem delimičnog kasko osiguranja
                obezbeđena je osiguravajuća zaštita za:<br><br>
                <ul>
                    <li>sva stakla standardno ugrađena na vozilu (prednja, zadnja i bočna)</li>
                </ul><br>
                Ovaj paket osiguranja ne podrazumeva zaštitu stakla ili plastike na svetlosnim uređajima, ogledalima ili
                krovu vozila (uključujući i stakleni krov).
            </span>
        </div>
        <div class="content-column1"
        style="background-image: url('../../../../../assets/images/osiguranje/kasko3.png');">

        </div>
    </div>
    <div class="content-row">
        <div class="content-column1"
        style="background-image: url('../../../../../assets/images/osiguranje/osiguranjekuce.webp');">

        </div>
        <div class="content-column2">
            <span class="title">Osiguranje kuće i stana - Mi čuvamo vaš dom, jer on čuva vas!</span>
            <span class="text">Dom je tamo gde se vaše srce oseća najsigurnije.
                Mi znamo kako da domu osigurate mir, bilo da je reč o iznenadnom pucanju cevi, polomljenom staklu zbog
                promaje, zemljotresu ili provalnoj krađi.</span>
        </div>
    </div>
</div>