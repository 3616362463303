import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { BlogService } from '../../../../services/blog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addblog',
  standalone: true,
  imports: [QuillEditorComponent,ReactiveFormsModule ],
  templateUrl: './addblog.component.html',
  styleUrls: ['./addblog.component.scss']
})
export class AddblogComponent {
  blogForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private blogService: BlogService,
    private toastr: ToastrService
  ) {
    // Inicijalizacija forme
    this.blogForm = this.fb.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
      image: ['', Validators.required]
    });
  }

  async submitContent() {
    if (this.blogForm.valid) {
      const formData = this.blogForm.value;

      this.blogService.save(formData).subscribe((response: any) => {
        if (response.status === 200) {
          this.toastr.success('Blog saved successfully');
        } else {
          this.toastr.error(response.message);
        }
      }, (error: any) => {
        this.toastr.error('Došlo je do greške.');
      });
    }
  }
}
