import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-kalulator-registracije',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './kalulator-registracije.component.html',
  styleUrls: ['./kalulator-registracije.component.scss','./kalulator-registracije-mobile.component.scss']
})
export class KalulatorRegistracijeComponent implements OnInit {
  data: any = {
    tipVozila: 1,
    godiste: null,
    snaga: null,
    zapremnina: null,
    premijskiStepen: 4,
    invalid: false,
    taxi: false,
    saobracajna: false,
    tablice: false,
    zeleniKarton: false,
    brojSteta: 0,
    usluga: 1,
    rentCar: false
  };
  cena: number = 0;
  modal: boolean = false;
  ngOnInit(): void {

  }
  constructor(private toastr: ToastrService) {

  }
  toggleModal() {
    this.modal = !this.modal;
  }


  rezultat: any = {
    komunalna: 0,
    polisa: 0,
    porez: 0,
    zeleniKarton: 0,
    tehnicki: 0,
    saobracajna: 0,
    tablice: 0,
    nalepnica: 0,
    taxi: 0,
    rentCar: 0,
    invalid: 0,
    sum: 0,
  }
  calculate(f: any) {
    if (!f) {
      this.toastr.warning("Molimo popunite ispravno formu.")
      return;
    }
    console.log(this.data)

    //komunalna taksa
    if (this.data.zapremnina >= 0 && this.data.zapremnina <= 1150)
      this.rezultat.komunalna = 780;
    else if (this.data.zapremnina >= 1151 && this.data.zapremnina <= 1300)
      this.rezultat.komunalna = 1550;
    else if (this.data.zapremnina >= 1301 && this.data.zapremnina <= 1600)
      this.rezultat.komunalna = 2350;
    else if (this.data.zapremnina >= 1601 && this.data.zapremnina <= 2000)
      this.rezultat.komunalna = 3150;
    else if (this.data.zapremnina >= 2001 && this.data.zapremnina <= 3000)
      this.rezultat.komunalna = 4740;
    else if (this.data.zapremnina >= 3001)
      this.rezultat.komunalna = 7840;


    //polisa
    if (this.data.snaga <= 22)
      this.rezultat.polisa = 9549;
    else if (this.data.snaga > 22 && this.data.snaga <= 33)
      this.rezultat.polisa = 11408;
    else if (this.data.snaga > 33 && this.data.snaga <= 44)
      this.rezultat.polisa = 13280;
    else if (this.data.snaga > 44 && this.data.snaga <= 55)
      this.rezultat.polisa = 15153;
    else if (this.data.snaga > 55 && this.data.snaga <= 66)
      this.rezultat.polisa = 17012;
    else if (this.data.snaga > 66 && this.data.snaga <= 84)
      this.rezultat.polisa = 19509;
    else if (this.data.snaga > 84 && this.data.snaga <= 110)
      this.rezultat.polisa = 23241;
    else if (this.data.snaga > 110)
      this.rezultat.polisa = 27597;


    let starost = new Date().getFullYear() - this.data.godiste;
    //porez
    if (this.data.zapremnina <= 1150) {
      if (starost <= 5)
        this.rezultat.porez = 1780;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 1513;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 1335;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 1068;
      if (starost >= 20)
        this.rezultat.porez = 356;
    }
    else if (this.data.zapremnina >= 1151 && this.data.zapremnina <= 1300) {
      if (starost <= 5)
        this.rezultat.porez = 3490;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 2966;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 2617;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 2094;
      if (starost >= 20)
        this.rezultat.porez = 698;
    }
    else if (this.data.zapremnina >= 1301 && this.data.zapremnina <= 1600) {
      if (starost <= 5)
        this.rezultat.porez = 7680;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 6528;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 5760;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 4608;
      if (starost >= 20)
        this.rezultat.porez = 1536;
    }
    else if (this.data.zapremnina >= 1601 && this.data.zapremnina <= 2000) {
      if (starost <= 5)
        this.rezultat.porez = 15750;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 13387;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 11812;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 9450;
      if (starost >= 20)
        this.rezultat.porez = 3150;
    }
    else if (this.data.zapremnina >= 2001 && this.data.zapremnina <= 2500) {
      if (starost <= 5)
        this.rezultat.porez = 77850;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 58387;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 46710;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 27247;
      if (starost >= 20)
        this.rezultat.porez = 15570;
    }
    else if (this.data.zapremnina >= 2501 && this.data.zapremnina <= 3000) {
      if (starost <= 5)
        this.rezultat.porez = 157730;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 118297;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 94638;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 55205;
      if (starost >= 20)
        this.rezultat.porez = 31546;
    }
    else if (this.data.zapremnina >= 3001) {
      if (starost <= 5)
        this.rezultat.porez = 326020;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 244515;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 195612;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 114107;
      if (starost >= 20)
        this.rezultat.porez = 65204;
    }


    if (this.data.zeleniKarton)
      this.rezultat.zeleniKarton = 2000;

    if (this.data.nalepnica)
      this.rezultat.nalepnica = 330 + 240;

    if (this.data.tehnicki)
      this.rezultat.tehnicki = 5000;
    if (this.data.saobracajna)
      this.rezultat.saobracajna = 780;

    if (this.data.tablice)
      this.rezultat.tablice = 1310;

    if (this.data.rentCar)
      this.rezultat.rentCar = this.rezultat.polisa * -0.4;

    if (this.data.taxi)
      this.rezultat.taxi = this.rezultat.polisa * 0.2;

    if (this.data.invalid)
      this.rezultat.invalid = this.rezultat.polisa * -0.1
    
    this.rezultat.sum = this.rezultat.porez + this.rezultat.polisa + this.rezultat.komunalna + this.rezultat.zeleniKarton + this.rezultat.nalepnica + this.rezultat.tehnicki + this.rezultat.saobracajna + this.rezultat.tablice+this.rezultat.rentCar + this.rezultat.taxi+this.rezultat.invalid;
    this.toggleModal();
  }

}
