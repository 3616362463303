<div class="banner">
    <span class="title">Dokumentacija</span>
    <span class="content">Na ovoj stranici možete pronaći svu potrebnu dokumentaciju za određene procedure, uključujući
        registraciju vozila, prenos vlasništva i sve što je povezano sa tehničkim pregledom i auto centrom.</span>
</div>
<div class="section">
    <div class="element" (click)="toggleModal('zaregistraciju')">
        <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6 1C4.34314 1 3 2.34315 3 4V20C3 21.6569 4.34315 23 6 23H19C20.6569 23 22 21.6569 22 20V10C22 9.73478 21.8946 9.48043 21.7071 9.29289L13.7071 1.29292C13.6114 1.19722 13.4983 1.1229 13.3753 1.07308C13.2572 1.02527 13.1299 1 13 1H6ZM12 3H6C5.44771 3 5 3.44771 5 4V20C5 20.5523 5.44772 21 6 21H19C19.5523 21 20 20.5523 20 20V11H13C12.4477 11 12 10.5523 12 10V3ZM18.5858 9.00003L14 4.41424V9.00003H18.5858Z"
                fill="#000000" />
        </svg>
        <span class="title">Dokumentacija za registraciju</span>
    </div>

    @if(showModal=='zaregistraciju'){
        <div class="modal">
            <div class="modal-overlay" (click)="toggleModal('')"></div>
            <div class="modal-content">
                <div class="modal-header"><img (click)="toggleModal('')" src="../../../../../assets/icons/close.svg"/></div>
                <div class="modal-body">
                    U našem auto centru možete registrovati vozilo kao pravno i kao fizičko lice.  Ukoliko registrujete vozilo kao pravno lice potrebno je da ponesete sa sobom saobraćajnu dozvolu, kopiju APR obrasca, pečat firme i polisu osiguranja. Na uvid donosite izvod banke kao dokaz o izvršenoj uplati za registraciju vozila za šta vam je potreban tačan iznos takse. 
                    <br>
                    Što se redovne registracije tiče, za fizička lica, potrebna je saobraćajna dozvola, lična karta i polisa. Često vlasnik automobila nije u mogućnosti da dođe i registruje auto, tada je potrebno doneti i ovlašćenje overeno kod notara da drugo lice može registrovati auto u ime vlasnika. Na licu mesta plaćate taksu, dobijate novu polisu osiguranja i nalepnicu.
                    Najčešća pitanja su da li je ista dokumatacija potrebna za redovnu i prvu registraciju, zato smo rešili da vam olakšamo i taj deo. Situacija je malo drugačija ako je u pitanju prva registracija vozila. Prva registracija motornog vozila je svaka registracija vozila koje je uvezeno iz inostranstva, bilo da je novo ili polovno, kao i registracija novog vozla koje je proizvedeno u Srbiji.
                    Od dokumentacije vam je potrebno da imate registracioni list sa dokazom o tehničkoj ispravnosti motornog vozila (ne stariji 30 dana), dokaz o poreklu (vlasništvo nad motornim vozilom poput saobraćajne dozvole, računa, ugovora o poklonu ili bilo koje pravosnažno rešenje o sticanju vozila), dokaz o identitetu vlasnika (lična karta, putna isprava za strance sa odobrenim boravkom u Republici Srbiji dužem od šest meseci.), carinska dokumentacija sa dokazom o izmirenim obavezama kao i dokaz o uplati propisanih troškova za registraciju vozila. Napominjemo da je za pravna lica potrebno i rešenje iz APR-a. 
                </div>
            </div>
        </div>
    }