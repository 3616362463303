import { Component } from '@angular/core';
import { ContactFormComponent } from '../home/contact-form/contact-form.component';

@Component({
  selector: 'app-kontakt',
  standalone: true,
  imports: [ContactFormComponent],
  templateUrl: './kontakt.component.html',
  styleUrl: './kontakt.component.scss'
})
export class KontaktComponent {

}
