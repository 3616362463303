@if(isVisible){
<div id="fsModal" class="modal animated bounceIn" tabindex="-1" (click)="handleClick()">
    <!-- dialog -->
    <div class="modal-dialog">
        <!-- content -->
        <div class="modal-content">
            <div class="header-modal">
                <div class="close" (click)="handleClick()">
                    <img class="close-img" src="../../../../../assets/icons/close.svg" />
                </div>
            </div>
            <!-- body -->
            <div class="modal-body">
                <div class="menu-item" (click)="navigate('/')">
                    <img src="../../../../../assets/icons/home.svg" />
                    Pocetna
                </div>

                <div class="menu-item" (click)="navigateId('/','contact')">
                    <img src="../../../../../assets/icons/contact.svg" />
                    Kontakt
                </div>

                <div class="menu-item" (click)="navigate('/blogovi')">
                    <img src="../../../../../assets/icons/blog.svg" />
                    Blog
                </div>

                <div class="menu-item" (click)="navigate('o-nama')">
                    <img src="../../../../../assets/icons/about.svg" />
                    O nama
                </div>

                <div class="menu-item" (click)="navigate('tehnicki-pregled')">
                    <img src="../../../../../assets/icons/tehnickipregled.svg" />
                    Tehnički pregled
                </div>

                <div class="menu-item" (click)="navigate('registracija-vozila')">
                    <img src="../../../../../assets/icons/registration.svg" />
                    Registracija vozila
                </div>

                <div class="menu-item" (click)="navigate('osiguranje')">
                    <img src="../../../../../assets/icons/osiguranje.svg" />
                    Osiguranje
                </div>

                <div class="menu-item" (click)="navigateId('tehnicki-pregled','registracijafotelja')">
                    <img src="../../../../../assets/icons/fotelja.svg" />
                    Registracija iz fotelje
                </div>

                <div class="menu-item" (click)="navigate('dokumentacija')">
                    <img src="../../../../../assets/icons/documentation.svg" />
                    Dokumentacija
                </div>

                <div class="menu-item" (click)="navigate('servis-vozila')">
                    <img src="../../../../../assets/icons/servicecar.svg" />
                    Servis vozila
                </div>

                <div class="menu-item" (click)="navigate('ispitivanje-vozila')">
                    <img src="../../../../../assets/icons/ispitivanjevozila.svg" />
                    Ispitivanje vozila
                </div>

                <div class="menu-item" (click)="navigate('ostale-usluge')">
                    <img src="../../../../../assets/icons/other.svg" />
                    Ostale usluge
                </div>
                <div class="menu-item" (click)="navigate('login')" style="background-color: #0B0078; color: white;">
                    <img src="../../../../../assets/icons/login-dark.svg" />
                    Loyalty program
                </div>
            </div>
            <!-- body -->
        </div>
        <!-- content -->
    </div>
    <!-- dialog -->
</div>

}