import { Component, OnInit, ViewChild } from '@angular/core';
import { PrivateMenuComponent } from './private-menu/private-menu.component';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { SidePrivateMobileMenuComponent } from './side-private-mobile-menu/side-private-mobile-menu.component';

@Component({
  selector: 'app-private-header',
  standalone: true,
  imports: [PrivateMenuComponent, SidePrivateMobileMenuComponent],
  templateUrl: './private-header.component.html',
  styleUrl: './private-header.component.scss'
})
export class PrivateHeaderComponent implements OnInit {
  ngOnInit(): void {
  }
  constructor(private router: Router, private authService: AuthService) {

  }

  modalVisible: boolean = false;
  navigate(route: string) {
    this.router.navigate([route]).then(() => {
      window.scrollTo(0, 0);
    });
  }
  togleModal() {
    this.modalVisible = !this.modalVisible;
    console.log(this.modalVisible, 'isvisible')
  }
  openTab(num: number) {
    this.router.navigate(['online-zakazivanje'])
    // this.popupComponentRef.changeState(num);
  }
  isAdmin() {
    return this.authService.isRole('Admin')
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }
  logout() {
    this.authService.logout();
  }
}
