import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-servis-vozila',
  standalone: true,
  imports: [],
  templateUrl: './servis-vozila.component.html',
  styleUrl: './servis-vozila.component.scss'
})
export class ServisVozilaComponent {
  constructor(private router : Router, private toastr : ToastrService ){
    this.router.navigate(['/']);
    this.toastr.warning('Uskoro više informacija o servisu vozila.');
  }
}
