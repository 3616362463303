import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ostale-usluge',
  standalone: true,
  imports: [],
  templateUrl: './ostale-usluge.component.html',
  styleUrl: './ostale-usluge.component.scss'
})
export class OstaleUslugeComponent {
  constructor(private router : Router, private toastr : ToastrService ){
    this.router.navigate(['/']);
    this.toastr.warning('Uskoro više informacija o našim dodatnim uslugama.');
  }
}
