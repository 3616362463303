import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlogService } from '../../../../services/blog.service';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from '../../../private/pagination/pagination.component';

@Component({
  selector: 'app-bloglist',
  standalone: true,
  imports: [CommonModule, PaginationComponent], 
  templateUrl: './bloglist.component.html',
  styleUrls: ['./bloglist.component.scss'] 
})
export class BloglistComponent implements OnInit {
  blogs: any[] = [];
  count: number = 0;
  pageData = {
    pageSize: 9,
    currentPage: 1,
    search: ''
  };

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private toastr: ToastrService, 
    private blogService: BlogService
  ) { }

  navigateBlog(url : string){
    this.router.navigate(['/blog/'+url]).then(() => {
      window.scrollTo(0, 0);
    });
  }

  ngOnInit(): void {
    this.reloadTable();
  }
  pageChange(value: number) {
    this.pageData.currentPage = value
    this.reloadTable()
  }
  reloadTable() {
    this.blogService.getAll(this.pageData).subscribe((response: any) => {
      if (response.status === 200) {
        this.blogs = response.data.data;
        this.count = response.data.count;
      } else {
        this.toastr.error(response.message);
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
}
