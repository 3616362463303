<!-- STATISTIKA -->
<div class="stats">
  <div class="col50">SLEDEĆI TERMIN <span class="value-stats">{{stats.nextAppointment}}</span></div>
  <div class="col25">BROJ VOZILA<span class="value-stats">{{stats.vehicleCount}}</span></div>
  <div class="col25">BROJ REFERALA<span class="value-stats">
      <span class="text">
        Iskorišćeni: {{stats.usedReferals}}<br>
        Na čekanju: {{stats.pendingReferals}}<br>
        Neiskorišćeni: {{stats.unusedReferals}}
      </span>
  </span></div>
</div>
<!-- DODAJ VOZILO -->
<button class="btn float-btn" (click)="selectUser(0)">
  <span class="text"><img src="../../../../assets/icons/add.svg" /></span>
</button>
<!-- TABELA -->
<div class="cars">
<div class="table">
  <table>
    <thead>
      <tr>
        <th>Ime</th>
        <th>Vozilo</th>
        <th>Registarska oznaka</th>
        <th>Datum isteka registracije</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data" (click)="selectUser(item.id)">
        <td>{{ item.name }}</td>
        <td>{{ item.brend }} {{ item.model }}</td>
        <td>{{ item.licensePlate }}</td>
        <td>{{ item.registrationExpirationDate | date:'dd.MM.yyyy' }}</td>
        <td class="actions"><img class="icons" title="Ukloni vozilo" (click)="$event.stopPropagation(); remove(item.id)" src="../../../../assets/icons/delete.svg"/>
        <img class="icons" *ngIf="isAdmin()" title="Transfer vozila na drugog korisnika" (click)="$event.stopPropagation();" src="../../../../assets/icons/transfer.svg"/></td>
      </tr>
    </tbody>
  </table>
</div>
  <div class="row-table">
    <app-pagination
    [pageSize]="pageData.pageSize"
    [currentPage]="pageData.currentPage"
    [count]="count"
    (pageChange)="pageChange($event)"
    >
    </app-pagination>  </div>
</div>