import { Component } from '@angular/core';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [],
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss', './about-us-tablet.component.scss', './about-us-mobile.component.scss']

})
export class AboutUsComponent {

}
