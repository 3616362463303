<div class="banner" style="background-image: url({{blog.image}})">
    <span class="title">{{ blog.naslov }}</span>
    <span class="postDate"><b>{{blog.postDate | date:'dd.MM.yyyy &nbsp;&nbsp; HH:mm' }}</b></span>
</div>
<div class="content">
    <div class="inner-content">
        <div [innerHTML]="blog.content"></div> 
    </div>
</div>
  
